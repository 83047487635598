<template>
  <site-sign-out></site-sign-out>
</template>

<script>
  // @ is an alias to /src
  import SiteSignOut from '@/components/forms/SiteSignOut.vue'

  export default {
    name: 'Home',
    components: {
      SiteSignOut
    }
  }
</script>
