<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Non Disclosure Agreement
        </h1>
        <v-form v-model="valid" ref="frmDetails">
          <v-text-field v-model="name" :rules="nameRules" :counter="22" label="Name" required></v-text-field>
          <v-text-field v-model="company" label="Company" required></v-text-field>
          <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
          <v-text-field v-model="address" :rules="addressRules" label="Address" required></v-text-field>
          <NDA :name="name" :address="address" :email="email" :company="company"></NDA>
          <signature v-on:signatureChange="updateSignature"></signature>
          <privacy></privacy>
          <v-btn :disabled="!(valid && hasSignature)" :loading="loading" block
            color="primary" elevation="2" @click="submit()">Agree</v-btn>
        </v-form>
      </v-col>
    </v-row>
    <snackbar :snackbar="snackbar" :text="snackmessage"></snackbar>
  </v-container>
</template>

<script>
  import NDA from '../conditions/NDA.vue'
  import Privacy from '../conditions/Privacy.vue'
  import Snackbar from '../extras/Snackbar.vue'
  import Signature from '../extras/Signature.vue'

export default {
    components: {
      NDA,
      Snackbar,
      Signature,
      Privacy
    },
    data: () => ({
      signPad: {},
      valid: false,
      snackbar: false,
      snackmessage: '',
      name: '',
      address: '',
      company: '',
      loading: false,
      addressRules: [
        v => !!v || 'Address is required',
      ],
      nameRules: [
        v => !!v || 'Name is required',
        v => v.length <= 22 || 'Name must be less than 22 characters',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    }),
    computed: {
      hasSignature() {
        return true
      }
    },
    methods: {
      updateSignature(data) {
        this.signPad = data
      },
      submit() {
        this.loading = true
        var accept = {
          Document: "1",
          Name: this.name,
          Email: this.email,
          Address: this.address,
          Date: new Date(),
          Company: this.company,
          Signature: this.signPad.toDataURL()
        }
        this.$axios.post('/accept/nda', accept).then(() => {
          window.scrollTo(0, 0)
          this.loading = false
          this.snackmessage = 'Thank you, your acceptance has been recieved.'
          this.snackbar = true
          setTimeout(() => {
            this.$router.go()
          }, 2000)
        }).catch((err) => {
          this.loading = false
          this.snackmessage = 'An error has occoured, please request support.'
          this.snackbar = true
          console.log(err);
          setTimeout(() => {
            this.$router.go()
          }, 2000)
        })
      }
    },
    mounted() {
      this.$axios.get('/info').then((res) => {
        console.log(res.data.msg);
      })
    }
  }
</script>