import Vue from 'vue'
import VueRouter from 'vue-router'
import ViewVisitorSignin from '../views/australia/forms/VisitorSignin.vue'
import ViewSiteSignOut from '../views/australia/forms/SignOut.vue'
import ViewNDA from '../views/australia/forms/NDA.vue'
import ViewGymWaiver from '../views/australia/forms/GymWaiver.vue'
import ViewAustralia from '../views/australia/Home.vue'
import E404 from '../views/global/E404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/australia',
    name: 'Australia',
    component: ViewAustralia
  },
  {
    path: '/australia/forms/nda',
    name: 'NDA',
    component: ViewNDA
  },
  {
    path: '/australia/forms/visitor-signin',
    name: 'Visitor',
    component: ViewVisitorSignin
  },
  {
    path: '/australia/forms/site-sign-out',
    name: 'Sign Out',
    component: ViewSiteSignOut
  },
  {
    path: '/australia/forms/gym-waiver',
    name: 'Gym Waiver',
    component: ViewGymWaiver
  },
  {
    path: '*',
    component: E404,
    name: '404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
