<template>
  <v-container fill-height fluid class="home myc-aus-bg">
    <v-row align="center" justify="center">
      <v-col cols="12" md="4">
        <div class="mx-5">
          <router-link to="/australia/forms/visitor-signin" class="text-decoration-none">
            <v-btn outlined large block class="rounded-xl">
              <span class="myc-font text-capitalize">Visitor Sign In</span>
            </v-btn>
          </router-link>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="mx-5">
          <router-link to="/australia/forms/nda" class="text-decoration-none">
            <v-btn outlined large block class="rounded-xl">
              <span class="myc-font text-capitalize">
                Complete NDA
              </span>
            </v-btn>
          </router-link>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="mx-5">
          <router-link to="/australia/forms/site-sign-out" class="text-decoration-none">
            <v-btn outlined large block class="rounded-xl">
              <span class="myc-font text-capitalize">
                Site sign out
              </span>
            </v-btn>
          </router-link>
        </div>
      </v-col>
      <v-col cols="0" md="3">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // @ is an alias to /src

  export default {
    name: 'Home',
  }
</script>