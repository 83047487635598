<template>
  <gym-waiver></gym-waiver>
</template>

<script>
  // @ is an alias to /src
  import GymWaiver from '@/components/forms/GymWaiver.vue'

  export default {
    name: 'Home',
    components: {
      GymWaiver,
    }
  }
</script>