<template>
  <div style="text-align: left;">
    <h1 class="display-5 font-weight-bold mb-3">
      Liability Waiver
    </h1>
    <p>This Agreement is made on:
      {{ new Date().toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric"}) }}
    </p>
    <p>I, {{ name }} of {{ address }}, being aware of my own health and physical condition, and having knowledge that my
      participation in any exercise program, physiotherapy, personal training or any other exercise or health related
      activity may be injurious to my health, am voluntarily participating in physical activity within Secure Data Link
      Pty Ltd and it’s related bodies corporate (Mycelium’s) gym and using Mycelium’s gym equipment at 138 Mary Street
      Brisbane QLD 4000.</p>
    <p>Having such knowledge, I hereby release Mycelium and its related bodies corporate, representatives and agents
      from liability for injury or illness which I may incur as a result of participating in physical activity in
      connection with the gym or gym equipment. I assume all risks connected with the use of the gym and gym equipment
      and consent to participating in personal training sessions supervised by Mycelium representatives and agents.</p>
    <p>I agree to disclose any physical limitations, disabilities, ailments, or impairments which may affect my ability
      to participate in the use of the gym or gym equipment. I confirm that I have recently attended a medical
      examination and have been deemed fit to exercise.</p>
  </div>
</template>

<script>
  export default {
    props: [
      'name',
      'address',
    ],
  }
</script>