<template>
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
        transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="date" label="Date of birth" prepend-icon="mdi-calendar" readonly v-bind="attrs"
            v-on="on"></v-text-field>
        </template>
        <v-date-picker active-picker="'YEAR'" v-model="date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(date)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
</template>

<script>
  export default {
    data: () => ({
      date: (new Date(Date.now() - 790000000000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
    }),
    watch: {
      date() {
        this.$emit('dateChange', this.date)
      }
    }
  }
</script>