<template>
  <NDA></NDA>
</template>

<script>
  // @ is an alias to /src
  import NDA from '@/components/forms/NDAAcceptance.vue'

  export default {
    name: 'Home',
    components: {
      NDA
    }
  }
</script>