<template>
  <visitor-signin></visitor-signin>
</template>

<script>
  // @ is an alias to /src
  import VisitorSignin from '@/components/forms/VisitorSignin.vue'

  export default {
    name: 'Home',
    components: {
      VisitorSignin
    }
  }
</script>
