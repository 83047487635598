<template>
  <div style="text-align: left;">
    <h1 class="text-h6">Privacy policy</h1>
    <p>We are committed to respecting your privacy. Our Privacy Policy sets out how we collect, use, store and disclose
      your personal information.
      By providing personal information to us, you consent to our collection, use and disclosure of your personal
      information in accordance with this Privacy Policy and any other arrangements that apply between us. We may change
      our Privacy Policy from time to time by publishing changes to it on our website. We encourage you to check our
      website periodically to ensure that you are aware of our current Privacy Policy.
      Personal information includes information or an opinion about an individual that is reasonably identifiable. For
      example, this may include your name, age, gender, postcode and contact details.
      For more information about the Mycelium’s privacy policy and privacy statements, please visit <a
        href="https://mycelium.ventures/privacy-policy/">https://mycelium.ventures/privacy-policy/</a>
    </p>
  </div>
</template>

<script>
  export default {
    props: [

    ],
    data() {
      return {}
    }
  }
</script>