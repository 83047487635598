<template>
  <div id="app">
    <v-app>
      <v-app-bar app dark color="transparent" :src="toolbarBackground">
        <div class="d-flex align-center">
          <router-link to="/australia">
          <v-img alt="Vuetify Logo" class="shrink mr-2" contain :src="require('@/assets/primary.png')"
            transition="scale-transition" width="120" />
          </router-link>
        </div>
      </v-app-bar>
      <v-main>
        <router-view />
      </v-main>
    </v-app>
  </div>
</template>

<style lang="scss">
@import "@/styles/_variables.scss";
</style>

<script>
export default {
  computed: {
    toolbarBackground() {
      var noToolbarRoutes = ["Australia", "404"]
      if(!noToolbarRoutes.includes(this.$route.name)) {
        return require('@/assets/toolbar.png')
      } else {
        return null
      }
    }
  }
}
</script>