<template>
  <div ref="signatureContainer">
    <h3>Please sign below</h3>
    <div style="background-color: white; margin-bottom: 5px;">
      <canvas ref="signature" class="signature-pad" :width="width" :height="height"></canvas>
    </div>
    <v-btn block color="secondary" elevation="2" @click="clear()">Clear Signature</v-btn>
  </div>
</template>

<script>
  import SignaturePad from 'signature_pad'

  export default {
    data() {
      return {
        signPad: {},
        width: 0,
        height: 0
      }
    },
    watch: {
      width() {
        this.signPad.clear()
      },
      signPad: {
        deep: true,
        handler() {
          this.$emit('signatureChange', this.signPad)
        }
      }
    },
    methods: {
      clear() {
        this.signPad.clear()
      },
      resize() {
        this.width = this.$refs.signatureContainer.clientWidth
        this.height = this.$refs.signatureContainer.clientWidth / 2.4
      }
    },
    computed: {
      hasSignature() {
        return this.signPad.isEmpty()
      }
    },
    mounted() {
      this.signPad = new SignaturePad(this.$refs.signature, {
        backgroundColor: 'rgb(250, 250, 250)'
      })
      this.resize()
    },
    created() {
      window.addEventListener("resize", this.resize);
    },
    destroyed() {
      window.removeEventListener("resize", this.resize);
    },
  }
</script>