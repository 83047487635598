<template>
  <v-container fill-height fluid class="home myc-aus-bg">
    <v-row align="center" justify="center">
      <v-col cols="0" md="3">
      </v-col>
      <v-col cols="12" md="3">
        <div class="mx-5">
          <router-link to="/australia/" class="text-decoration-none">
            <v-btn outlined large block class="rounded-xl">
              <span class="myc-font text-capitalize">
                Return Home
              </span>
            </v-btn>
          </router-link>
        </div>
      </v-col>
      <v-col cols="12" md="3">
        <div class="mx-5" align="center">
          <h1 class="myc-error-title">Not found</h1>
          <h3 class="myc-error-subtitle">404</h3>
        </div>
      </v-col>

      <v-col cols="0" md="3">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // @ is an alias to /src

  export default {
    name: 'Home',
  }
</script>