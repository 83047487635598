<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Site Sign Out
        </h1>
        <v-form v-model="valid" ref="frmDetails">
          <v-text-field v-model="name" :rules="nameRules" :counter="22" label="Name" required></v-text-field>
          <v-select :items="offices" item-text="name" item-value="name" label="Office location" v-model="officeLocation"></v-select>
          <v-text-field v-model="purpose" label="Purpose" required></v-text-field>
          <v-menu ref="menu" v-model="timeMenu" :close-on-content-click="false" :nudge-right="40"
            :return-value.sync="departure" transition="scale-transition" offset-y max-width="290px" min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field required v-model="departure" label="Departure time"
                prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-time-picker color="primary" v-if="timeMenu" v-model="departure" :allowed-minutes="allowedMinutes"
              full-width @click:minute="$refs.menu.save(departure)">
            </v-time-picker>
          </v-menu>
          <privacy></privacy>
          <v-row>
            <v-col>
              <v-btn :disabled="!(valid)" :loading="loading" block color="primary" elevation="2" @click="submit()">Agree
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <snackbar :snackbar="snackbar" :text="snackmessage"></snackbar>
  </v-container>
</template>

<script>
  import Privacy from '../conditions/Privacy.vue'
  import Snackbar from '../extras/Snackbar.vue'

  export default {
    components: {
      Privacy,
      Snackbar
    },
    data: () => ({
      offices: [{
          name: 'Brisbane',
          coords: {
            latitude: -27.4705,
            longitude: 153.0260
          }
        },
        {
          name: 'Sydney',
          coords: {
            latitude: -33.8688,
            longitude: 151.2093
          }
        },
        {
          name: 'Buderim',
          coords: {
            latitude: -26.6857,
            longitude: 153.0507
          }
        }
      ],
      officeLocation: 'Brisbane',
      valid: false,
      snackbar: false,
      snackmessage: '',
      timeMenu: null,
      name: '',
      purpose: 'Site Lock-up',
      departure: `${new Date().getHours()}:${new String(new Date().getMinutes()).padStart(2, '0')}`,
      loading: false,
      nameRules: [
        v => !!v || 'Name is required',
        v => v.length <= 22 || 'Name must be less than 22 characters',
      ],
    }),
    methods: {
      async submit() {
        this.loading = true
        var accept = {
          Name: this.name,
          'Created at': new Date(),
          'Modified at': new Date(),
          'Office': this.officeLocation,
          'Purpose': this.purpose,
          'Time': this.departure,
        }
        await this.$axios.post('/site/sign-out', accept).then(() => {
          window.scrollTo(0, 0)
          this.loading = false
          this.snackmessage = 'Thank you, your sign out has been saved.'
          this.snackbar = true
          this.$refs.frmDetails.reset()
          setTimeout(() => {
            this.$router.go()
          }, 2000)
        }).catch((err) => {
          this.loading = false
          this.snackmessage = 'An error has occoured, please request support.'
          this.snackbar = true
          console.log(err);
          setTimeout(() => {
            this.$router.go('/australia')
          }, 2000)
        })
      },
      allowedMinutes: v => v % 5 === 0
    },
    mounted() {
      this.$axios.get('/info').then((res) => {
        console.log(res.data.msg);
      })
    }
  }
</script>