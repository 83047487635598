<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Gym Waiver
        </h1>
        <v-form v-model="valid" ref="frmDetails">
          <v-text-field v-model="signatory.name" :rules="nameRules" :counter="22" label="Name" required></v-text-field>
          <!-- <v-autocomplete v-model="address" :items="items" :loading="isLoading" :search-input.sync="search" color="white"
            hide-no-data hide-selected item-text="Description" item-value="id" label="Address"
            placeholder="Start typing to lookup address" prepend-icon="mdi-database-search" return-object></v-autocomplete> -->
          <v-text-field v-model="signatory.address" label="Address" required></v-text-field>
          <!-- <v-date-picker v-model="signatory.dob" label="DOB" required></v-date-picker> -->
          <date-picker v-on:dateChange="updateDOB"></date-picker>
          <v-text-field v-model="signatory.email" :rules="emailRules" label="E-mail" required></v-text-field>
          <v-text-field v-model="signatory.phone" label="Phone" required></v-text-field>
          <v-text-field v-model="emergency.name" label="Emergency Contact Name" required></v-text-field>
          <v-text-field v-model="emergency.phone" :rules="phoneRules" label="Emergency Contact Phone" required>
          </v-text-field>
          <v-text-field v-model="emergency.relationship" label="Emergency Contact Relationship"></v-text-field>
          <gym v-if="(signatory.name && signatory.address)" :name="signatory.name" :address="signatory.address"></gym>
          <signature v-on:signatureChange="updateSignature"></signature>
          <privacy></privacy>
          <v-btn v-if="this.signPad.isEmpty" :disabled="!(valid && !this.signPad.isEmpty())" :loading="loading" block
            color="primary" elevation="2" @click="submit()">Agree</v-btn>
        </v-form>
      </v-col>
    </v-row>
    <snackbar :snackbar="snackbar" :text="snackmessage"></snackbar>
  </v-container>
</template>

<script>
  import Gym from '../conditions/Gym.vue'
  import Privacy from '../conditions/Privacy.vue'
  import Snackbar from '../extras/Snackbar.vue'
  import Signature from '../extras/Signature.vue'
  import DatePicker from '../extras/DatePicker.vue'

  export default {
    components: {
      Snackbar,
      Gym,
      Signature,
      Privacy,
      DatePicker
    },
    watch: {
      search(val) {
        // Items have already been loaded
        // if (this.items.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true
        console.log(this.address)

        // Lazily load input items
        this.$axios.post('http://localhost:3000/address/autocomplete', {
            "address": val
          })
          .then(res => {
            const {
              count,
              entries
            } = res.data
            this.count = count
            this.entries = entries
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },
    },
    data: () => ({
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      signPad: {},
      valid: false,
      snackbar: false,
      snackmessage: '',
      signatory: {
        name: '',
        address: '',
        dob: '',
        email: '',
        phone: ''
      },
      emergency: {
        name: '',
        phone: '',
        relationship: '',
      },
      loading: false,
      addressRules: [
        v => !!v || 'Address is required',
      ],
      nameRules: [
        v => !!v || 'Name is required',
        v => v.length <= 22 || 'Name must be less than 22 characters',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    }),
    computed: {
      fields() {
        if (!this.model) return []

        return Object.keys(this.model).map(key => {
          return {
            key,
            value: this.model[key] || 'n/a',
          }
        })
      },
      items() {
        return this.entries.map(entry => {
          const Description = entry.Description.length > this.descriptionLimit ?
            entry.Description.slice(0, this.descriptionLimit) + '...' :
            entry.Description

          return Object.assign({}, entry, {
            Description
          })
        })
      },
    },
    methods: {
      updateDOB(data) {
        this.signatory.dob = data
        console.log(data)
      },
      updateSignature(data) {
        this.signPad = data
      },
      async submit() {
        this.loading = true
        var accept = {
          Name: this.signatory.name,
          DOB: this.signatory.dob,
          Email: this.signatory.email,
          Address: this.signatory.address,
          Phone: this.signatory.phone,
          'Emergency Contact Name': this.emergency.name,
          'Emergency Contact Phone': this.emergency.phone,
          'Emergency Contact Relationship': this.emergency.relationship,
          Date: new Date(),
          Company: this.company,
          Signature: this.signPad.toDataURL()
        }
        await this.$axios.post('/accept/gym-waiver', accept).then(() => {
          window.scrollTo(0, 0)
          this.loading = false
          this.snackmessage = 'Thank you, your gym waiver has been recieved.'
          this.snackbar = true
          setTimeout(() => {
            this.$router.go()
          }, 2000)
        }).catch((err) => {
          window.scrollTo(0, 0)
          this.loading = false
          this.snackmessage = 'An error has occoured, please request support.'
          this.snackbar = true
          console.log(err);
          setTimeout(() => {
            this.$router.go()
          }, 2000)
        })
      }
    },
    mounted() {
      this.$axios.get('/info').then((res) => {
        console.log(res.data.msg);
      })
    }
  }
</script>